import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AxiosInstance from './AxiosInstance';
import Header from './Header';
import ImageUploadComponent from './ImageUploadComponent';
import "./projectdetail.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus, faPencilAlt, faUndoAlt } from '@fortawesome/free-solid-svg-icons';

const ProjectDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const fileInputRefs = useRef([]);
  const [projectInfo, setProjectInfo] = useState({ name: '', bannerUrl: '' });

  const initialFormState = {
    assetType: '',
    assetName: '',
    process: '',
    status: '',
    manDays: '',
    image: null,
    imageName: '',
    description: '',
    collapsed: false
  };

  const [forms, setForms] = useState([initialFormState]);
  const [assetTypeOptions, setAssetTypeOptions] = useState([]);
  const [assetNameOptions, setAssetNameOptions] = useState({});
  const [processOptions, setProcessOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await AxiosInstance.get(`/api/project/${id}/`);
        const { project_name, assets, types, phases, status } = response.data;

        // Set project-specific info for the banner
        setProjectInfo({
          name: project_name,
          bannerUrl: `../project_icon/${id}_icon.jpg` // Assuming the project has a banner image URL
        });

        setAssetTypeOptions(types);
        setProcessOptions(phases);
        setStatusOptions(status);
        setAssetNameOptions(
          assets.reduce((acc, asset) => {
            acc[asset.asset_type_id] = acc[asset.asset_type_id] || [];
            acc[asset.asset_type_id].push(asset.asset_name);
            return acc;
          }, {})
        );
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };

    fetchProjectData();
  }, [id, setProjectInfo]);

  useEffect(() => {
    fileInputRefs.current = fileInputRefs.current.slice(0, forms.length);
  }, [forms]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const handleInputChange = (index, field, value) => {
    const newForms = [...forms];
    newForms[index][field] = value;

    if (field === 'assetType') {
      newForms[index].assetName = '';
      newForms[index].process = '';
      newForms[index].status = '';
    } else if (field === 'assetName') {
      newForms[index].process = '';
      newForms[index].status = '';
    } else if (field === 'process') {
      newForms[index].status = '';
    }

    setForms(newForms);
    setHasUnsavedChanges(true);
  };

  const handleImageChange = (index, file) => {
    const newForms = [...forms];
    newForms[index] = { ...newForms[index], image: file, imageName: file.name};
    setForms(newForms);
    setHasUnsavedChanges(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = forms.map(validateForm);
    const hasErrors = errors.some((error) => !Object.values(error).every((isValid) => isValid));

    // If there are errors, set them and prevent submission
    if (hasErrors) {
      setFormErrors(errors);
      return;
    }

    try {
      await Promise.all(
        forms.map(async (form, index) => {
          const formData = new FormData();
          formData.append('assetType', form.assetType);
          formData.append('assetName', form.assetName);
          formData.append('process', form.process);
          formData.append('status', form.status);
          formData.append('manDays', form.manDays);
          console.log(form.manDays)
          formData.append('description', form.description);
          if (form.image) {
            formData.append('image', form.image);
          }
          
          await AxiosInstance.post(`/api/daily_log/${id}/`, formData);

          // Clear the file input field after successful submission
          if (fileInputRefs.current[index]) {
            fileInputRefs.current[index].value = '';
          }
        })
      );

      alert('Log entries created successfully!');
      setForms([initialFormState]); // Reset forms
      setHasUnsavedChanges(false); // Reset unsaved changes flag
      setFormErrors([]); // Clear form errors

    } catch (error) {
      console.error('Error creating log entries:', error);
      alert('Failed to create log entries.');
    }
  };

  const addForm = () => {
    const newForms = forms.map((form) => ({ ...form, collapsed: true }));
    newForms.push({ ...initialFormState, collapsed: false });
    setForms(newForms);
    setHasUnsavedChanges(true);
  };

  const deleteForm = (index) => {
    const newForms = forms.filter((_, i) => i !== index);

    // Adjust the file input references after deleting a form
    fileInputRefs.current = fileInputRefs.current.filter((_, i) => i !== index);

    setForms(newForms);
    setHasUnsavedChanges(true);
  };

  const clearForm = (index) => {
    const newForms = [...forms];
    newForms[index] = { ...initialFormState, image: null };

    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].value = '';
    }
    setForms(newForms);
    setHasUnsavedChanges(true);
  };

  const toggleCollapse = (index) => {
    const newForms = forms.map((form, i) => ({
      ...form,
      collapsed: i !== index ? true : !form.collapsed,
    }));
    setForms(newForms);
    setHasUnsavedChanges(true);
  };

  const validateForm = (form) => {
    const validation = {
      assetType: !!form.assetType,
      assetName: !!form.assetName,
      process: !!form.process,
      status: !!form.status,
      manDays: !!form.manDays,
      description: !!form.description,
      image: !!form.image,
    };
  
    return Object.values(validation).every(value => value);
  };

  const isFormValid = forms.every(validateForm);

  const getInputClassName = (index, field) => {
    return formErrors[index] && formErrors[index][field] === false ? 'error-border' : '';
  };

  return (
    <div className='pd'>
      <Header />
      {/* Banner section */}
      <div className="project-banner">
        {projectInfo.bannerUrl ? (
          <img src={projectInfo.bannerUrl} alt={`${projectInfo.name} Banner`} className="project-banner-image" />
        ) : (
          <h2 className="project-banner-title">{projectInfo.name}</h2>
        )}   
      </div>
      
      <form onSubmit={handleSubmit}>
        <div className='dailylogform'>
          <table className='form-table'>
            <thead className='form-header'>
              <tr>
                <th>Asset Type</th>
                <th>Asset Name</th>
                <th>Process</th>
                <th>Status</th>
                <th>Man-days</th>
                <th>Description</th>
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {forms.map((form, index) => (
                <tr key={index} className={form.collapsed ? 'form-row disabled' : 'form-row editable'} onClick={() => form.collapsed && toggleCollapse(index)}>
                  <td>
                    <select
                      value={form.assetType}
                      onChange={(e) => handleInputChange(index, 'assetType', e.target.value)}
                      className={getInputClassName(index, 'assetType')}
                      disabled={form.collapsed}
                    >
                      <option value="">Select...</option>
                      {assetTypeOptions.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={form.assetName}
                      onChange={(e) => handleInputChange(index, 'assetName', e.target.value)}
                      className={getInputClassName(index, 'assetName')}
                      disabled={form.collapsed || !form.assetType}
                    >
                      <option value="">Select...</option>
                      {form.assetType &&
                        assetNameOptions[form.assetType]?.map((name) => (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={form.process}
                      onChange={(e) => handleInputChange(index, 'process', e.target.value)}
                      className={getInputClassName(index, 'process')}
                      disabled={form.collapsed || !form.assetName}
                    >
                      <option value="">Select...</option>
                      {processOptions.map((process) => (
                        <option key={process.id} value={process.id}>
                          {process.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      value={form.status}
                      onChange={(e) => handleInputChange(index, 'status', e.target.value)}
                      className={getInputClassName(index, 'status')}
                      disabled={form.collapsed || !form.process}
                    >
                      <option value="">Select...</option>
                      {statusOptions.map((status) => (
                        <option key={status.id} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={form.manDays}
                      onChange={(e) => handleInputChange(index, 'manDays', e.target.value)}
                      className={getInputClassName(index, 'manDays')}
                      min="0.5"
                      step="0.5"
                      placeholder="Select..."
                      disabled={form.collapsed || !form.status}
                    />
                  </td>
                  <td>
                    <textarea
                      value={form.description}
                      onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                      className={getInputClassName(index, 'description')}
                      placeholder='Enter task details or comments...'
                      disabled={form.collapsed || !form.manDays}
                    />
                  </td>
                  <td>
                    <ImageUploadComponent 
                      index={index}
                      form={form}
                      handleImageChange={handleImageChange}
                      fileInputRefs={fileInputRefs}
                      imageName={form.imageName}
                    />
                  </td>
                  <td>
                    <div className='action-bar'>
                      {!form.collapsed ? (
                        <>
                          <button type="button" onClick={() => deleteForm(index)} className="delete-form-button">
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                          <button type="button" onClick={() => clearForm(index)} className="delete-form-button">
                            <FontAwesomeIcon icon={faUndoAlt} />
                          </button>
                        </>
                      ) : (
                        <button type="button" onClick={() => toggleCollapse(index)} className="delete-form-button-collapsed">
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="form-actions">
          <button type="button" onClick={addForm} className="add-button" disabled={!isFormValid}>
            <FontAwesomeIcon icon={faPlus} /> Add Task
          </button>
          <button type="submit" className="submit-button" disabled={!isFormValid || forms.length === 0}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProjectDetail;
